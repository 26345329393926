import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class BasicInterceptor implements HttpInterceptor {
    constructor(
        private storageService: StorageService,
        private router: Router,
        private toastrService: ToastrService
    ) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        let authorization: any = "";
        if (req.url.includes('password/token')) {
            const clientTokenJson = localStorage.getItem('ClientAccessToken');
            if (clientTokenJson) {
                const clientToken = JSON.parse(clientTokenJson);
                if (clientToken) {
                    authorization = `Bearer ${clientToken.access_token}`;
                }
            }
        }
        else {
            if (req.url.indexOf('oauth') != -1) {
                //do nothing
            } else if (
                req.url.indexOf('api/v1/filter') != -1 
                || req.url.indexOf('api/auth') != -1
                || req.url.indexOf('api/v1/campaigns') != -1
                || req.url.indexOf('api/v1/countries/1/home') != -1) {
                const token: any = this.storageService.getClientAccessToken();
                if (token && token.expires_in > Date.now())
                    authorization = `Bearer ${this.storageService.getClientAccessToken().access_token}`;
            } else if (req.url.indexOf('api/v1') != -1) {
                const token = this.storageService.getAccessToken();
                if (token && token.expires_in > Date.now())
                    authorization = `Bearer ${this.storageService.getAccessToken().access_token}`
                else {
                    // this.router.navigate(['/']);
                    // this.toastrService.error('Token expired, redirecting to login.','Error')
                    localStorage.removeItem('AccessToken');
                    this.router.navigate(['/login']);
                }
            }
            const _token = localStorage.getItem('AccessToken');
            if (_token !== null) {
                authorization = `Bearer ${JSON.parse(_token).access_token}`;
            }
            const token = this.storageService.getAccessToken();
            if (token && token.expires_in < Date.now()) {
                authorization = '';
            }

            let storedToken = _token ? JSON.parse(_token) : null;
            if (_token && storedToken && token && storedToken.access_token !== token.access_token) {
                this.router.navigate(['/login']);
                this.toastrService.error('Token mismatch, redirecting to login.', 'Error')
            }


            if (storedToken && token && token.expires_in < Date.now()) {
                authorization = '';
                localStorage.removeItem('AccessToken');
            }
        }
        const headers = new HttpHeaders({
            platform: 'WEB',
            'app-version': '1.00',
            accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods':
                'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
            'Authorization': authorization,
            "X-Client-Platform": "Web-App",
            "X-Client-Version": "2.0.0",
            "ngrok-skip-browser-warning": "69420"

        });
        if (!req.headers.has('Content-Type')) {
            headers.set('Content-Type', 'application/json')
        }
        else {
            headers.set('accept', '*/*')
        }
        const clonedRequest = req.clone({ headers });

        return next.handle(clonedRequest);
    }

}
